import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const GAboutTitleRow = styled(Grid)(() => ({
  marginTop: "70px",
  marginBottom: "30px",
  maxWidth: "90%",
  marginLeft: "auto",
  marginRight: "auto",
}));

export const GAboutRow = styled(Grid)(() => ({
  padding: "0px 0px 50px 0px",
  margin: "0 !important",
  flexGrow: 1,
}));

export const AboutTitle = styled("p")(({ theme }) => ({
  fontSize: "40px",
  color: theme.palette.secondary.contrastText,
  textAlign: "center" as const,
  fontFamily: "Brittany"
}));

export const AboutTitleBorders = styled("div")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.contrastText}`,
  width: "8%",
  marginLeft: "4px",
  marginRight: "4px",
  opacity: "0.85",
  "@media (max-width:960px)": {
    width: "0",
  },
}));
