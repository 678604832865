import { styled } from "@mui/material";
import HomeImage from "./HomeImage";
import { Presentation } from "./Presentation";
import { Projets } from "./Projets";
import { Offres } from "./Offres";
import { Helmet } from "react-helmet";
import favicon from "../../mabe.png";

const Root = styled("div")(() => ({
  flexGrow: 1,
}));

function Home() {
  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title> MaBe créations | Nous découvrir </title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <HomeImage />
      <Projets />
      <Offres />
      <Presentation />
    </Root>
  );
}

export { Home };
