import { useTheme } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import {
  GAboutTitleRow,
  AboutTitleBorders,
  AboutTitle,
} from "../common/styles/common";
import {
  GAllRowsWrapper,
  GRowWrapper,
  GMaxRowWrapper,
  GTextWrapper,
  Strong,
  GImageWrapper,
  GMobileRowWrapper,
  GMobileImageWrapper,
} from "./styles/presentation";


function Offres() {
  const theme = useTheme();

  return (
    <GAllRowsWrapper
      id="offers"
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      spacing={4}
    >
      <GAboutTitleRow
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <AboutTitleBorders />
        <AboutTitle>Découvrir nos offres</AboutTitle>
        <AboutTitleBorders />
      </GAboutTitleRow>
      <GAllRowsWrapper
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <GMobileRowWrapper>
        <GMobileImageWrapper item xs={12}>
          <img style={{  maxWidth: "100%",
  height: "auto",
  display: "block"
}} src="/mabe/deco_interieur.png"></img>
        </GMobileImageWrapper>
        <GMobileImageWrapper item xs={12}>
          <img style={{  maxWidth: "100%",
  height: "auto",
  display: "block"
}} src="/mabe/amenagement_exterieur.png"></img>
        </GMobileImageWrapper>
        <GMobileImageWrapper item xs={12}>
          <img style={{  maxWidth: "100%",
  height: "auto",
  display: "block"
}} src="/mabe/home_staging.png"></img>
        </GMobileImageWrapper>

      </GMobileRowWrapper>
      <GMaxRowWrapper>
        <GRowWrapper
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={4}
        style={{backgroundColor: theme.palette.colors.background, border: `2px solid ${theme.palette.colors.borders}` }}
        >
          <GImageWrapper item xs={12}>
            <img src="/mabe/offres.png"></img>
          </GImageWrapper>
        </GRowWrapper>
        </GMaxRowWrapper>
      </GAllRowsWrapper>

    </GAllRowsWrapper>
  );
}

export { Offres };
