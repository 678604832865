import { createTheme, responsiveFontSizes } from "@mui/material";

type ThemeVariant = "light" | "dark" | "system";

const green = "#293731";
const brown = "#d9cfa3";
const white = "#FFFFFF";
const background = "#F6F5EA";

let theme = createTheme({
  palette: {
    primary: {
      main: brown,
      contrastText: green,
      light: white,
    },
    secondary: {
      main: green,
      contrastText: brown,
      light: white,
    },
    colors: {
      background: background,
      white: white,
      borders: brown,
    },
    status: {
      error: "#9d1d32",
      success: "#2d793a"
    },
  },
  variables: {
    headerHeight: {
      sm: 56,
      default: 64,
    },
  },
});
theme = responsiveFontSizes(theme);

export const getStyledProps = (...props: string[]) => ({
  shouldForwardProp: (prop: any) => !props.includes(prop as any),
});

declare module "@mui/material/styles" {
  interface CustomPalette {
    colors: {
      background: string;
      white: string;
      borders: string;
    }
    status: {
      error: string;
      success: string;
    };
  }

  interface CustomVariables {
    headerHeight: {
      sm: number;
      default: number;
    };
  }

  interface CustomTheme {
    palette: CustomPalette;
    variables: CustomVariables;
  }

  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export type { ThemeVariant };
export { theme };
