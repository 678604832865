import { Button, Link, useTheme } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
  GAboutTitleRow,
  AboutTitleBorders,
  AboutTitle,
} from "../common/styles/common";
import {
  GAllRowsWrapper,
  GRowWrapper,
  CarouselSlide,
  Legend,
} from "./styles/presentation";

function Projets() {
  const theme = useTheme();

  return (
    <GAllRowsWrapper
      id="projects"
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      spacing={4}
    >
      <GAboutTitleRow
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <AboutTitleBorders />
        <AboutTitle> Nos réalisations </AboutTitle>
        <AboutTitleBorders />
      </GAboutTitleRow>
      <GRowWrapper
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={4}
        style={{width: "60%"}}
      >
        <Carousel swipeable={true} infiniteLoop={true} >
            <CarouselSlide>
                <img src="/mabe/jardin_clos.png" />
                <Legend className="legend">Le jardin clos</Legend>
            </CarouselSlide>
            <CarouselSlide>
                <img src="/mabe/terrasse_toulousaine.png" />
                <Legend className="legend">La terrasse toulousaine</Legend>
            </CarouselSlide>
            <CarouselSlide>
                <img src="/mabe/jardin_du_sud.png" />
                <Legend className="legend">Le jardin du Sud</Legend>
            </CarouselSlide>
        </Carousel>
      </GRowWrapper>
    </GAllRowsWrapper>
  )
}

export { Projets };
