import React from "react";
import { EmptyContainer, BackgroundImage, ArrowDown, BackgroundMobile } from "./styles/home";

function HomeImage() {
  return (
    <EmptyContainer
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <BackgroundImage src="/mabe/home.png" alt="Logo mabe avec jardin japonais" />
      <BackgroundMobile src="/mabe/home_mobile.png" alt="Logo mabe avec jardin japonais" />
      <ArrowDown />
      <ArrowDown />
    </EmptyContainer>
  );
}

export default HomeImage;
