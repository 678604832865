import { useTheme } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import {
  GAboutTitleRow,
  AboutTitleBorders,
  AboutTitle,
} from "../common/styles/common";
import {
  GAllRowsWrapper,
  GRowWrapper,
  GMaxRowWrapper,
  GTextWrapper,
  Strong,
  GMobileRowWrapper,
} from "./styles/presentation";


function Presentation() {
  const theme = useTheme();

  return (
    <GAllRowsWrapper
      id="about"
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      spacing={4}
    >
      <GAboutTitleRow
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <AboutTitleBorders />
        <AboutTitle>MaBe | Qui sommes-nous ?</AboutTitle>
        <AboutTitleBorders />
      </GAboutTitleRow>
      <GAllRowsWrapper
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <GMaxRowWrapper>
        <GRowWrapper
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={4}
        style={{backgroundColor: theme.palette.colors.background, border: `2px solid ${theme.palette.colors.borders}` }}
        >
          <GTextWrapper item xs={12}>
            <p> MaBe se spécialise dans la décoration d'intérieur et l'aménagement paysager, offrant des solutions sur mesure pour transformer vos espaces de vie et extérieurs en véritables havres de paix.
 < br />
 < br />
Avec un décorateur d’intérieur et une paysagiste expérimentée, nous combinons <Strong>créativité</Strong> et <Strong> expertise technique</Strong> pour créer des environnements esthétiques et fonctionnels qui répondent à vos besoins et
reflètent votre personnalité. 

< br />
 < br />
Que vous souhaitiez moderniser votre intérieur avec 
des éléments <Strong>contemporains</Strong> ou créer un jardin luxuriant et <Strong>accueillant</Strong>, nous mettons un point d'honneur à utiliser des
matériaux de qualité et à respecter les délais convenus. 

< br />
 < br />

Chez MaBe, votre <Strong>satisfaction</Strong> est notre priorité
et nous sommes dédiés à vous accompagner à chaque étape de votre projet, de conception.
</p>
          </GTextWrapper>
        </GRowWrapper>
        </GMaxRowWrapper>
      <GMobileRowWrapper>
        <GRowWrapper
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={4}
        style={{backgroundColor: theme.palette.colors.background, border: `2px solid ${theme.palette.colors.borders}` }}
        >
          <GTextWrapper item xs={12}>
            <p> MaBe se spécialise dans la décoration d'intérieur et l'aménagement paysager, offrant des solutions sur mesure pour transformer vos espaces de vie et extérieurs en véritables havres de paix.
 < br />
 < br />
Avec un décorateur d’intérieur et une paysagiste expérimentée, nous combinons <Strong>créativité</Strong> et <Strong> expertise technique</Strong> pour créer des environnements esthétiques et fonctionnels qui répondent à vos besoins et
reflètent votre personnalité. 

< br />
 < br />
Que vous souhaitiez moderniser votre intérieur avec 
des éléments <Strong>contemporains</Strong> ou créer un jardin luxuriant et <Strong>accueillant</Strong>, nous mettons un point d'honneur à utiliser des
matériaux de qualité et à respecter les délais convenus. 

< br />
 < br />

Chez MaBe, votre <Strong>satisfaction</Strong> est notre priorité
et nous sommes dédiés à vous accompagner à chaque étape de votre projet, de conception.
</p>
          </GTextWrapper>
        </GRowWrapper>
        </GMobileRowWrapper>
      </GAllRowsWrapper>

    </GAllRowsWrapper>
  );
}

export { Presentation };
