import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { Header } from "./modules/Header";
import { Home } from "./modules/Home";

function App() {
  return (
    <Routes>
      <Route path="" element={<Header />}>
        <Route path="accueil" element={<Home />} />
        <Route path="" element={<Navigate to="accueil" />} />
        <Route path="*" element={<Navigate to="accueil" />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default App;
