import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { Instagram } from "@mui/icons-material";

const MailOrPhone = styled("a")(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.secondary.contrastText,
  whiteSpace: "nowrap",
  "&:hover": {
    color: theme.palette.secondary.light,
  },
}));

const FooterText = styled(Grid)(({ theme }) => ({
  margin: "auto",
  textAlign: "center",
  padding: "2%",
  fontWeight: "bolder",
  color: theme.palette.secondary.contrastText,
}));

const GFooterRow = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: "0 !important",
  margin: "0 !important",
  backgroundColor: theme.palette.secondary.main,
  "@media (max-width:960px)": {
    "& .MuiGrid-item": {
      paddingLeft: "0 !important",
    },
  },
}));

function Footer() {
  return (
    <GFooterRow
      id="contact"
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={4}
    >
      <Grid item md={4} xs={12}>
        <FooterText item xs={12}>
          MaBe
        </FooterText>
      </Grid>
      <Grid item md={4} xs={12}>
        <FooterText item xs={12}>
          <MailOrPhone href={`mailto:mabe.conception@gmail.com`}>
            {" "}
            <MailOutlineIcon
              style={{
                position: "relative",
                verticalAlign: "middle",
                paddingRight: "3px",
              }}
            />
            mabe.conception@gmail.com{" "}
          </MailOrPhone>
        </FooterText>
        <FooterText item xs={12}>
          <MailOrPhone href={`tel:+33613870353`}>
            {" "}
            <PhoneOutlinedIcon
              style={{
                position: "relative",
                verticalAlign: "middle",
                paddingRight: "3px",
              }}
            />
            06 13 87 03 53{" "}
          </MailOrPhone>
        </FooterText>
        <FooterText item xs={12}>
          <MailOrPhone href={`http://instagram.com/_u/mabe_conception/`}>
            {" "}
            <Instagram
              style={{
                position: "relative",
                verticalAlign: "middle",
                paddingRight: "3px",
              }}
            />
            @mabe_conception{" "}
          </MailOrPhone>
        </FooterText>
      </Grid>
      <Grid item md={4} xs={12}>
        <FooterText item xs={12}>
          © 2024 Tous droits réservés
        </FooterText>
      </Grid>
    </GFooterRow>
  );
}

export { Footer };
