import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PresentationName = styled("p")(({ theme }) => ({
  textAlign: "center" as const,
  fontSize: "30px",
  textDecoration: "underline",
  color: theme.palette.secondary.contrastText,
}));

export const GPresentationTextRows = styled(Grid)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  textAlign: "center" as const,
  maxWidth: "85%",
  "@media (max-width:960px)": {
    padding: "inherit",
  },
  "@media (min-width:961px)": {
    padding: "50px 25px 50px 25px !important",
  },
}));

export const PresentationParagraph = styled("p")(({ theme }) => ({
  alignSelf: "center" as const,
  maxWidth: "90%",
  margin: "auto",
  lineHeight: "30px",
  "& a": {
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));

export const GImageWrapper = styled(Grid)(() => ({
  width: "80%",
  textAlign: "center",
}));

export const GMobileImageWrapper = styled(Grid)(() => ({
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "50px",
  width: "80%",
  textAlign: "center",
}));

export const ImageRow = styled("img")(() => ({
  borderRadius: "20%",
  width: "80%",
  padding: "5%",
  marginTop: "10%",
}));

export const LogoRow = styled("img")(() => ({
  borderRadius: "15%",
  height: "60px",
}));

export const LargeImageRow = styled("img")(() => ({
  borderRadius: "5%",
  width: "70%",
  marginTop: "3%",
  marginBottom: "3%",
}));

export const GRowWrapper = styled(Grid)(() => ({
  width: "90%",
  marginLeft: "auto",
  borderRadius: "20px",
  marginTop: 0,
  marginRight: "auto",
  alignItems: "stretch",
  "@media (max-width:960px)": {
    maxWidth: "100%",
  },
  "& .MuiGrid-root": {
    flexGrow: 1,
  },
}));



export const GMaxRowWrapper = styled(Grid)(() => ({
  width: "90%",
  borderRadius: "20px",
  marginBottom: "8%",
  marginLeft: "auto",
  marginRight: "auto",
  padding: '2%', 
  backgroundColor: "white",
  alignItems: "stretch",
  "@media (max-width:960px)": {
    display: "none"
  },
  "& .MuiGrid-root": {
    flexGrow: 1,
  },
}));

export const GMobileRowWrapper = styled(Grid)(() => ({
  width: "80%",
  marginBottom: "8%",
  marginLeft: "auto",
  marginRight: "auto",
  padding: '2%', 
  // backgroundColor: "white",
  alignItems: "stretch",
  "@media (min-width:961px)": {
    display: "none"
  },
  // "& .MuiGrid-root": {
  //   flexGrow: 1,
  // },
}));

export const GTextWrapper = styled(Grid)(({ theme }) => ({
  width: "90%",
  textAlign: "center",
  color: theme.palette.primary.contrastText,
  paddingTop: "50px !important",
  paddingBottom: "50px",
  paddingLeft: "10% !important",
  paddingRight: "10%",
  "& .MuiGrid-root": {
    flexGrow: 1,
  },
}));

export const Strong = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  textDecoration: "underline",
  color: theme.palette.primary.contrastText,
}));

export const GAllRowsWrapper = styled(Grid)(() => ({
  alignItems: "stretch",
}));

export const CarouselSlide = styled("div")(({ theme }) => ({
  maxHeight: '80vh',
}));

export const Legend = styled("p")(({ theme }) => ({
  opacity: '0.75 !important',
  backgroundColor: `${theme.palette.colors.background} !important`,
  color: `${theme.palette.primary.contrastText} !important`,
  fontWeight: "bolder !important"
}));