import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export const EmptyContainer = styled(Grid)(() => ({
  position: "relative",
  height: "85vh",
  width: "100%",
  zIndex: "0",
  margin: "0 !important",
}));

export const BackgroundImage = styled("img")(() => ({
  position: "absolute" as const,
  height: "inherit",
  width: "inherit",
  zIndex: "10",
  margin: "0 !important",
  objectFit: "contain" as const,
  "@media (max-width:960px)": {
    display: "none" as const,
  },
}));

export const BackgroundMobile = styled("img")(() => ({
  position: "absolute" as const,
  height: "inherit",
  width: "inherit",
  zIndex: "10",
  margin: "0 !important",
  paddingTop: '10%',
  "@media (min-width:961px)": {
    display: "none"
  },
}));

export const ArrowDown = styled(DoubleArrowIcon)(({ theme }) => ({
  position: "absolute" as const,
  bottom: "6%",
  zIndex: "10",
  height: "65px",
  width: "65px",
  color: theme.palette.secondary.contrastText,
  transform: "rotate(90deg)",
  "@media (max-width:960px)": {
    marginTop: '15%',
    bottom: "-10%",
  },
}));
