import React from "react";
import { Box, styled } from "@mui/material";
import { slide as Menu } from "react-burger-menu";
import "../../css/BurgerButton.css";
import { Button, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer";

const RootHeader = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  zIndex: 40,
  "@media (max-width:960px)": {
    paddingBottom: "7%",
  },
  backgroundColor: theme.palette.colors.white,
  opacity: "0.95",
  marginRight: 0,
  marginLeft: 0,
  position: "fixed" as const,
  width: "100% !important"
}));

const ButtonMobile = styled("a")(({ theme }) => ({
  fontWeight: "bold" as const,
  color: theme.palette.primary.contrastText,
  minWidth: "auto",
  fontSize: "15px",
  textDecoration: "none",
  padding: "12px 6px 12px 6px !important",
  "&:hover": {
    color: "red",
  },
}));

const MobileMenu = styled(Menu)(({ theme }) => ({
  "@media (min-width:961px)": {
    visibility: "visible" as const,
    position: "relative",
    backgroundColor: theme.palette.colors.background,
  },
  "@media (max-width:960px)": {
    visibility: "visible" as const,
    position: "relative",
    backgroundColor: theme.palette.colors.background,
  },
}));

const ButtonDesktop = styled(Button)(({ theme }) => ({
  fontWeight: "bold" as const,
  fontSize: "11.5px",
  color: theme.palette.secondary.contrastText,
  minWidth: "auto",
  "&:hover": {
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ButtonDesktopLogo = styled(Button)(({ theme }) => ({
  fontWeight: "bold" as const,
  fontSize: "11.5px",
  padding: 0,
  color: theme.palette.secondary.contrastText,
  minWidth: "auto",
}));

const HeaderLogo = styled("img")(({ theme }) => ({
  height: "40px" as const,
}));

const GridButtonDesktop = styled(Grid)(() => ({
  padding: "12px 6px 12px 6px !important",
  "@media (max-width:960px)": {
    visibility: "hidden" as const,
    height: 0,
    padding: 0,
  },
}));

const GridButtonsRow = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "0 !important",
  marginLeft: "0 !important",
  "@media (max-width:960px)": {
    visibility: "hidden" as const,
    height: 0,
    padding: 0,
  },
}));

const GNavigation = styled(Grid)(() => ({
  flexGrow: 1,
  paddingRight: "45px !important",
}));

function Header() {
  return (
    <>
      <RootHeader
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={3}
      >
        <GridButtonDesktop item alignSelf={"flex-end"}>
          <ButtonDesktopLogo href="#"><HeaderLogo src="/mabe/logo_mabe_transparent.png" /></ButtonDesktopLogo>
        </GridButtonDesktop>
        <GNavigation item xs={10}>
          <MobileMenu right disableAutoFocus>
            <ButtonMobile href="#"> Accueil </ButtonMobile>
            <ButtonMobile href="#projects"> Projets </ButtonMobile>
            <ButtonMobile href="#offers"> Offres </ButtonMobile>
            <ButtonMobile href="#about"> MaBe </ButtonMobile>
            <ButtonMobile href="#contact"> Contact </ButtonMobile>
          </MobileMenu>
          <GridButtonsRow
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-end"
            spacing={3}
          >
            <GridButtonDesktop item>
              <ButtonDesktop href="#projects"> Projets </ButtonDesktop>
            </GridButtonDesktop>
            <GridButtonDesktop item>
              <ButtonDesktop href="#offers"> Offres </ButtonDesktop>
            </GridButtonDesktop>
            <GridButtonDesktop item>
              <ButtonDesktop href="#about"> MaBe </ButtonDesktop>
            </GridButtonDesktop>
            <GridButtonDesktop item>
              <ButtonDesktop href="#contact"> Contact </ButtonDesktop>
            </GridButtonDesktop>
          </GridButtonsRow>
        </GNavigation>
      </RootHeader>
      <Box>
        <Outlet />
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
}

export { Header };
